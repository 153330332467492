import React from "react";
import { Icon } from '@iconify/react';

class NavBar extends React.Component {
    componentDidMount = () => {
        window.addEventListener('scroll', () => {
            const nav = document.querySelector('.nav-bar');
            const contentEnd = document.querySelector(".content").scrollHeight - 250
            if (window.scrollY > contentEnd) nav.classList.add('gone');
            else if (window.scrollY < contentEnd) nav.classList.remove('gone');
        });
    }

    render = () => {
        return (
            <div className="nav-bar">
                <span className="logo">
                    <a href="https://tronic64.com">Tronic64</a>
                </span>
                <div className="links">
                    <a href="https://tronic64.com">
                        <Icon icon="akar-icons:home" />
                        Home</a>
                    <a href="#projects">
                    <Icon icon="akar-icons:folder" inline={true} />
                         Projects
                    </a>
                </div>
            </div>
        );
    }
}

export default NavBar;