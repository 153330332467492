import React from "react";

/**
 * Description: Project component. Titile, description, and image.
 * @param props {Object}
 */

class Project extends React.Component {
  render = () => {
    return (
      <div onClick={() => window.location.href = this.props.link} className="flex flex-col p-3" >
        <div className={`
            bg-white
            rounded-lg
            transition-all
            hover:shadow-xl
            shadow-lg
            overflow-hidden
            flex-1
            flex 
            flex-col
            cursor-pointer
            project
          `}>
          <img
            src={this.props.img}
            alt={this.props.description}
          />
          <div className="p-4 flex-1 flex flex-col" style={{}}>
            <h3 className="mb-4 text-2xl">
              {this.props.title}
            </h3>
            <div className="mb-4 text-grey-darker text-sm flex-1">
              <p>{this.props.description}</p>
            </div>
            <span className="border-t"></span>
            <a
              href={'https://www.google.com/search?q=' + this.props.lib.replace(/\s+/g, "+")}
              className="w-max hover:underline pt-2  text-sm text-grey hover:text-red no-underline tracking-wide"
            >
              {this.props.lib}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Project;