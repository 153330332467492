import React from "react";
import { Icon } from '@iconify/react';
import 'animate.css';

class AppFooter extends React.Component {
    render() {
        return (
            <footer>
                <div>
                    Made by ajTronic.
                </div>
                <p>
                    <span>Click</span>
                    <Icon icon="bx:like" onClick={e => e.target.classList.toggle("clicked")} inline={true} />
                    <span>
                        if you like the website!
                    </span>
                </p>
            </footer>
        );
    }
}

export default AppFooter;