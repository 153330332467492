import React from 'react';
import NavBar from './Components/NavBar.js';
import Content from './Components/Content.js';
import Projects from './Components/Projects.js';
import '../css/Style.css';
import AppFooter from "./Components/Footer.js"

class App extends React.Component {
  render = () => {
    return (
      <div className="App">
        <div className='base'>
          <NavBar/>
          <Content/>
        </div>
        <Projects/>
        <AppFooter/>
      </div>
    );    
  }
}

export default App;
