import React from "react";
import Project from "./Project";

class Projects extends React.Component {
  render() {
    return (
      <div className="projects">
        <h1>My projects</h1>
        <div className="projects-container" id="projects">
          <Project
            title="3D Fox"
            img="/imgs/projects/Fox.png"
            link="/projects/three.js/Fox/"
            description={
              `This is a 3D fox written in THREE.js, a javascript framework for building 3D apps.`
            }
            lib="Three.js"
          />
          <Project
            title="Bouncing Balls"
            img="/imgs/projects/BouncingBalls.png"
            link="/projects/p5.js/bouncingBalls/"
            description={
              `This project was written in p5.js. It is a simple bouncing ball game.`
            }
            lib="P5.js"
          />
          <Project
            title="Tic Tac Toe"
            img="/imgs/projects/TicTacToe.png"
            link="/projects/react/TicTacToe/"
            description={
              `This is a Tic Tac Toe game written in React.js. It features an unbeatable AI.`
            }
            lib="React"
          />
          <Project
            title="Binary Counter"
            img="/imgs/projects/BinaryNumberCounter.png"
            link="/projects/javascript/Toggles/"
            description={
              `This is a binary counter written in JavaScript. It contains toggles that make a binary number. The binary number is then converted into decimal.`
            }
            lib="Native JS"
          />
          <Project
            title="Rain"
            img="/imgs/projects/Rain.png"
            link="/projects/p5.js/rain/"
            description={
              `Blue rain in p5.js. The colours of each drop is slightly different. We fix the red and blue components and vary the green component. `
            }
            lib="P5.js"
          />
          <Project
            title="Alien Fractions"
            img="/imgs/projects/AlienFraction.png"
            link="/projects/p5.js/AlienFractions/"
            description={
              `These are alien fractions. They are insanely cool,
               but if you stare at them too long the aliens will beam into
               your eyeballs.`
            }
            lib="P5.js"
          />
          <Project
            title="Super Math"
            img="/imgs/projects/DarkMath.jpg"
            link="/projects/p5.js/DarkMath/"
            description={
              `P5.js math game. This game will seriously change your mind about multiplication. WARING: May be addictive.`
            }
            lib="P5.js"
          />
          <Project
            title="The Matrix Effect"
            img="/imgs/projects/MatrixEffect.png"
            link="/projects/p5.js/matrixEffect/"
            description={
              `The Matrix Effect in p5.js. Letters will rain down your monitor making a wonderfull effect.`
            }
            lib="P5.js"
          />
          {/* <Project
            title="Typewriter"
            img="/imgs/projects/Typewriter.png"
            link="/projects/javascript/Typing/"
            description={
              `Javascript Typewriter. You can type in the text box and a text will be displayed as if you were using a typewriter.`
            }
            lib="Native JS"
          /> */}
          <Project
            title="Roman Numeral Converter"
            img="/imgs/projects/RomanNumeralConverter.jpg"
            link="projects/javascript/RomanNumeralConverter/"
            description={
              `Converts a Roman Numeral to a number, and vice versa.`
            }
            lib="Vanilla JS"
          />
          <Project
            title="Snow"
            img="/imgs/projects/Snow.png"
            link="/projects/p5.js/snow/"
            description={
              <span>Snow in p5.js. It may seem complicated, but <a href="https://www.youtube.com/shorts/apM0Hr2_O0A">Here</a> is a video where I code it.</span>
            }
            lib="P5.js"
          />
          <Project
            title="Glitchtron"
            img="/imgs/projects/Glitchtron.png"
            link="https://zenotronic.itch.io/glitchtron"
            description="A 2D 1-bit platformer written in Godot."
            lib="Godot"
          />
          
          <Project
            title="One Button Quest"
            img="\projects\imgs\The One Button Quest Logo.png"
            link="https://zenotronic.itch.io/one-button-quest"
            description="A 2D game where you have to avoid obstacles and collect multipliers."
      lllllllll      lib="Godot"
          />
        <Project
            title="Unicode Art"vvvvvvvvvvvgvvvvvvvvvvvvvvvvvvcccvvvvvv
            img="/projects/javascript/unicode/images/einstein.png"
            link="/projects/javascript/unicode"
            description={<span>A project created for viewing unicode art. <a href="https://www.youtube.com/watch?v=NMb5nHg_cRs">Youtube video</a></span> }
            lib="Vanilla JS"
          />
          <Project
            title="Super ExpecTed"
            img="https://img.itch.zone/aW1nLzE1NTAyMzg2LnBuZw==/315x250%23c/4QXHEM.png"
            link="https://zenotronic.itch.io/super-expected"
            description={<span>A (sorta) horror platformer created in Godot.</span> }
            lib="Godot"
          />
          <Project
            title="Knight's Tour"
            img="/projects/imgs/tour.png"
            link="/projects/p5.js/KnightsTour"
            description={<span>A visualization of the Knight's tour written in p5.js</span> }
            lib="p5.js"
          />
                  </div>
      </div>
    );
  }
}
export default Projects;                  