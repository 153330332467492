import React from "react";
import { Icon } from '@iconify/react';
import * as THREE from 'three'

class Content extends React.Component {
    componentDidMount() {
        window.onload = () => {
            if (window.VANTA) {
                window.VANTA.HALO({
                    el: ".content",
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    forceAnimate: true,
                    backgroundColor: '#22232B',
                    yOffset: 0.1,
                    xOffset: 0.15,
                    scale: 1.2,
                    size: 0.8
                })
            }
        }
    }

    render = () => {
        return (
            <div className="content">
                { /** font size set to 0 so that there is no spacing. https://stackoverflow.com/questions/5078239/how-to-remove-the-space-between-inline-inline-block-elements  */}
                <div className="content-text">
                    <h1 className="main-heading">
                        <span>This</span> is
                        <br></br>
                        tronic64.
                    </h1>
                    <div className="text">
                        <p>
                            Here, at Tronic64, you can find all kinds of projects, including Tic Tac Toe and Alien Fractions.
                        </p>
                    </div>
                    <a href="#projects" className="btn">
                        <Icon icon="carbon:cube-view" inline={true} width={35} />
                        <span>View Projects</span>
                    </a>
                </div>

                <img src="/imgs/Transition.svg" className='svgTransition'></img>
            </div>
        );
    }
}

export default Content;